import * as React from 'react'

import { useMutation, useQuery } from '@apollo/client'
import {
  Button,
  Collapse,
  Stack,
} from '@mui/material'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import esLocale from 'date-fns/locale/es'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'

import { ButtonContainer, ButtonsContainer, ErrorDisplay, Loading } from '../../../components'
import {
  BaseIdentityFields,
  BooleanField,
  CivilStateFields,
  PhoneNumberFields,
  baseIdentityValidationSchema,
  civilStateValidationSchema,
  getBaseIdentityInitialValues,
  getCivilStateInitialValues,
  getPhoneNumberInitialValues,
  phoneNumberValidationSchema,
} from '../../../forms'
import {
  PERSON_IDENTITY_QUERY,
  PHONE_NUMBER_QUERY,
  UPDATE_PERSON_PERSON_IDENTITY_MUTATION,
} from '../../../queries'
import {
  booleanToString,
  setFormError,
  stringToBoolean,
  toISO8601Date,
  translateError,
} from '../../../services'
import {
  SpouseIdentityForm,
  getSpouseIdentityFormValues,
  getSpouseIdentityMutationVariables,
  spouseIdentityValidationSchema,
} from '../spouse_identity_form'

import type {
  BaseIdentityFormValues,
  CivilStateFormValues,
  PhoneNumberFormValues,
} from '../../../forms'
import type {
  PersonIdentity,
  PersonIdentityData,
  PersonIdentityVars,
  PhoneNumber,
  PhoneNumberData,
  PhoneNumberVars,
  UpdatePersonPersonIdentityData,
  UpdatePersonPersonIdentityVars,
} from '../../../queries'
import type { SpouseIdentityFormValues } from '../spouse_identity_form'
import type { FormikProps } from 'formik'
import type { ExecutionResult } from 'graphql'

type FormValues =
  {
    isPoliticallyExposed: string
    spouseIdentity: SpouseIdentityFormValues
  }
  & BaseIdentityFormValues
  & CivilStateFormValues
  & PhoneNumberFormValues

const getInitialValues = (
  personIdentity?: PersonIdentity,
  phoneNumber?: PhoneNumber,
): FormValues => ({
  isPoliticallyExposed: booleanToString(personIdentity?.isPoliticallyExposed),
  spouseIdentity: getSpouseIdentityFormValues(personIdentity?.spouseIdentity),
  ...getBaseIdentityInitialValues(personIdentity),
  ...getCivilStateInitialValues(personIdentity),
  ...getPhoneNumberInitialValues(phoneNumber),
})

const getMutationVariables = (data: FormValues): UpdatePersonPersonIdentityVars => ({
  birthdate: toISO8601Date(data.birthdate),
  familyName: data.familyName,
  givenName: data.givenName,
  identificationNumber: data.identificationNumber || '',
  nationality: data.nationality,
  civilState: data.civilState,
  isPoliticallyExposed: stringToBoolean(data.isPoliticallyExposed),
  spouseIdentity: data.civilState === 'MARRIED'
    ? getSpouseIdentityMutationVariables(data.spouseIdentity)
    : undefined,
  primaryPhoneNumber: data.primaryPhoneNumber,
  secondaryPhoneNumber: data.secondaryPhoneNumber || '',
})

const validationSchema: Yup.SchemaOf<FormValues> =
  Yup.object().shape({
    isPoliticallyExposed: Yup.string()
      .oneOf(['true', 'false'], 'Debe seleccionar una opción')
      .required('Debe seleccionar una respuesta'),
    spouseIdentity: spouseIdentityValidationSchema
      .when('civilState', {
        is: 'MARRIED',
        then: (schema) => schema,
        otherwise: () => Yup.object(),
      }),
    ...baseIdentityValidationSchema.fields,
    ...civilStateValidationSchema.fields,
    ...phoneNumberValidationSchema.fields,
  })

const StepForm = ({
  errors,
  isSubmitting,
  isValid,
  setFieldValue,
  status,
  submitForm,
  touched,
  values,
}: FormikProps<FormValues>) => (
  <Form>
    <Stack spacing={3}>
      <BaseIdentityFields />
      <CivilStateFields />
      <BooleanField
        label='¿Usted es o ha sido una Persona Expuesta Políticamente (PEP)?'
        name='isPoliticallyExposed'
      />
    </Stack>
    <Stack spacing={3}>
      <Collapse in={values.civilState == 'MARRIED'}>
        <SpouseIdentityForm
          spouseIdentity={values.spouseIdentity}
          setSpouseIdentity={(values) => setFieldValue('spouseIdentity', values)}
        />
        {Boolean(errors.spouseIdentity) && (
          <ErrorDisplay
            errorMsg={touched.spouseIdentity
              ? 'La información del cónyuge contiene errores'
              : 'Debe agregar la información de cónyuge'}
            my={1}
          />
        )}
      </Collapse>
      <PhoneNumberFields />
      <ErrorDisplay errorMsg={status?.errorMsg} />
    </Stack>
    <ButtonsContainer sx={{ mt: 2 }}>
      <ButtonContainer xs={12}>
        <Button
          fullWidth
          disabled={isSubmitting || !isValid}
          onClick={submitForm}
          variant='contained'
        >
          Continuar
        </Button>
      </ButtonContainer>
    </ButtonsContainer>
  </Form>
)

type PersonIdentityStepProps = {
  handleNext: () => void
}

export const PersonIdentityStep = ({
  handleNext,
}: PersonIdentityStepProps) => {
  const formRef = React.useRef<FormikProps<FormValues>>(null)

  const { loading: personIdentityLoading, data: personIdentityData } =
    useQuery<PersonIdentityData, PersonIdentityVars>(PERSON_IDENTITY_QUERY)

  const { loading: phoneNumberLoading, data: phoneNumberData }
    = useQuery<PhoneNumberData, PhoneNumberVars>(PHONE_NUMBER_QUERY)

  const [updatePersonIdentityStep] =
    useMutation<UpdatePersonPersonIdentityData, UpdatePersonPersonIdentityVars>(
      UPDATE_PERSON_PERSON_IDENTITY_MUTATION, {
        errorPolicy: 'all',
        refetchQueries: [
          PERSON_IDENTITY_QUERY,
          PHONE_NUMBER_QUERY,
        ],
      })

  const handleResponse = (responses: (string | undefined)[], response: ExecutionResult) => {
    if (responses.every((response) => response === 'OK!')) {
      return handleNext()
    }

    setFormError(formRef, translateError(response))
  }

  const handleSubmit = async(values: FormValues) => {
    const variables = getMutationVariables(values)

    const response = await updatePersonIdentityStep({ variables })
    handleResponse(
      [response.data?.updatePersonIdentity, response.data?.updatePhoneNumber],
      response,
    )
  }

  return (personIdentityLoading || phoneNumberLoading) ? (
    <Loading />
  ) : (
    <React.Fragment>
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        adapterLocale={esLocale}
      >
        <Formik
          innerRef={formRef}
          initialValues={getInitialValues(
            personIdentityData?.personIdentity,
            phoneNumberData?.phoneNumber,
          )}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {(props) => <StepForm {...props} />}
        </Formik>
      </LocalizationProvider>
    </React.Fragment>
  )
}
