import * as React from 'react'

import { Box, Stack, Typography } from '@mui/material'

import { AppContainer, CompositionGraph } from '../components'
import { Fund } from '../queries'

const graphHeight = 280

const CompositionGraphWrapper = ({ fund }: { fund: Fund } ) => {
  const dummyParent = {
    id: 'portfolio',
    parent: null,
    size: null,
  }
  const data = fund.assets
    .map((asset, index) => ({
      id: asset,
      parent: 'portfolio',
      size: fund.percentages[index],
    }))
    .filter((item) => item.size !== 0)

  return (
    <Box sx={{ marginTop: 2, height: graphHeight }}>
      <CompositionGraph
        totalQuoteAmount={100}
        data={[dummyParent, ...data]}
      />
    </Box>
  )
}

type FundDisplayProps = {
  fund: Fund
  onClick?: () => void
  children?: React.ReactNode
}

export const FundDisplay = ({
  fund,
  onClick,
  children,
}: FundDisplayProps) => (
  <AppContainer
    sx={{ p: 2, ...(onClick && { cursor: 'pointer' }) }}
    onClick={onClick}
  >
    <Stack
      direction='row'
      justifyContent='space-between'
    >
      <Typography variant='h4'>
        {fund.name}
      </Typography>
      <Stack direction='row'>
        {children}
      </Stack>
    </Stack>
    <CompositionGraphWrapper fund={fund} />
  </AppContainer>
)
