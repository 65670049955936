import * as React from 'react'

import { Box } from '@mui/material'

import { CompositionGraph, TickerPricesGraph } from '../../components'

import type { GraphType, PriceGraphPoint } from '..'
import type { PortfolioBalance } from '../../services'

type CompositionGraphWrapperProps = {
  portfolioBalances: PortfolioBalance[]
  totalQuoteAmount: number
}

const CompositionGraphWrapper = ({
  portfolioBalances,
  totalQuoteAmount,
}: CompositionGraphWrapperProps) => {
  const dummyParent = {
    id: 'portfolio',
    parent: null,
    size: null,
  }
  const data = portfolioBalances
    .filter((portfolioBalance) => portfolioBalance.totalAmount > 0)
    .map((portfolioBalance) => ({
      id: portfolioBalance.symbol,
      parent: 'portfolio',
      size: portfolioBalance.totalAmount * portfolioBalance.quotePrice,
    }))

  return (
    <Box sx={{ p: 2, height: '100%' }}>
      <CompositionGraph
        totalQuoteAmount={totalQuoteAmount}
        data={[dummyParent, ...data]}
      />
    </Box>
  )
}

type TickerPricesGraphWrapperProps = {
  graphUnit: string
  assetPrices: PriceGraphPoint[]
  ath: number
  atl: number
}

const TickerPricesGraphWrapper = ({
  graphUnit,
  assetPrices,
  ath,
  atl,
}: TickerPricesGraphWrapperProps) => (
  <Box sx={{ py: 1, height: '100%' }}>
    <TickerPricesGraph
      currency={graphUnit}
      data={assetPrices}
      ath={ath}
      atl={atl}
    />
  </Box>
)

type GraphSectionProps = {
  graphType: GraphType
  graphUnit: string
  portfolioBalances: PortfolioBalance[]
  totalQuoteAmount: number
  assetPrices: PriceGraphPoint[]
  ath: number
  atl: number
}

export const GraphSection = ({
  graphType,
  graphUnit,
  portfolioBalances,
  totalQuoteAmount,
  assetPrices,
  ath,
  atl,
}: GraphSectionProps) => {
  switch (graphType) {
  case 'COMPOSITION': return (
    <CompositionGraphWrapper
      portfolioBalances={portfolioBalances}
      totalQuoteAmount={totalQuoteAmount}
    />
  )
  case 'TOTAL_AMOUNT': return (
    <TickerPricesGraphWrapper
      graphUnit={graphUnit}
      assetPrices={assetPrices}
      ath={ath}
      atl={atl}
    />
  )
  default: return null
  }
}
