import { gql } from '@apollo/client'

export const FUND_ASSET_LABELS: { [key in string]: string } = {
  'BTC': 'Bitcoin',
  'ETH': 'Ethereum',
  'ADA': 'Cardano',
  'DOT': 'Polkadot',
  'SOL': 'Solana',
  'USDT': 'USD Tether',
  'USDC': 'USD Coin',
}

export type Fund = {
  id: string
  assets: string[]
  name: string
  percentages: number[]
  totalBalance: number
}

const FUND_FIELDS = gql`
  fragment FundFields on Fund {
    id
    assets
    name
    percentages
    totalBalance
  }
`

type Funds = {
  funds: Fund[]
  totalBalance: number
}

const FUNDS_FIELDS = gql`
  ${FUND_FIELDS}
  fragment FundsFields on Funds {
    funds {
      ...FundFields
    }
    totalBalance
  }
`

export type FundsVars = Record<string, never>

export type FundsData = {
  funds: Funds
}

export const FUNDS_QUERY = gql`
  ${FUNDS_FIELDS}
  query Funds {
    funds {
      ...FundsFields
    }
  }
`
