import { gql } from '@apollo/client'

import { STORAGE_POST_FIELDS } from './storage_post'

import type { StoragePost } from './storage_post'

export type PoiContentType = 'application/pdf' | 'image/heic' | 'image/jpeg' | 'image/jpg' | 'image/png'

export const POI_CONTENT_TYPE_LABELS: { [key in PoiContentType]: string } = {
  'application/pdf': 'Documento PDF',
  'image/heic': 'Imagen HEIC',
  'image/jpeg': 'Imagen JPEG',
  'image/jpg': 'Imagen JPG',
  'image/png': 'Imagen PNG',
}

export type PersonType = 'ID_CARD_FRONT' | 'ID_CARD_BACK' | 'PASSPORT'

export const PERSON_DOCUMENT_TYPE_LABELS: { [key in PersonType]: string } = {
  'ID_CARD_FRONT': 'Carnet de identidad (frente)',
  'ID_CARD_BACK': 'Carnet de identidad (reverso)',
  'PASSPORT': 'Pasaporte',
}

export type BusinessType = 'VALIDITY_CERTIFICATE' | 'COMPANY_CONSTITUTION'

export const BUSINESS_DOCUMENT_TYPE_LABELS: { [key in BusinessType]: string } = {
  'VALIDITY_CERTIFICATE': 'Certificado de vigencia',
  'COMPANY_CONSTITUTION': 'Constitución de la empresa',
}

export type PoiDocumentType = PersonType | BusinessType

export const POI_DOCUMENT_TYPE_LABELS = {
  ...PERSON_DOCUMENT_TYPE_LABELS,
  ...BUSINESS_DOCUMENT_TYPE_LABELS,
}

export type IdentityProof = {
  id: string
  contentType: string
  documentType: PoiDocumentType
  expirationDate?: string
  storagePost: StoragePost
}

export const IDENTITY_PROOF_FIELDS = gql`
  ${STORAGE_POST_FIELDS}
  fragment IdentityProofFields on IdentityProof {
    id
    contentType
    documentType
    expirationDate
    storagePost {
      ...StoragePostFields
    }
  }
`

export type CreateIdentityProofsVars = {
  contentTypeBack?: string
  contentTypeFront: string
  documentType: string
  expirationDate: string | null
}

export type CreateIdentityProofsData = {
  createIdentityProofs: IdentityProof[]
}

export const CREATE_IDENTITY_PROOFS_MUTATION = gql`
  ${IDENTITY_PROOF_FIELDS}
  mutation CreateIdentityProofs(
    $contentTypeBack: String
    $contentTypeFront: String!
    $documentType: String!
    $expirationDate: ISO8601Date
  ) {
    createIdentityProofs(
      input: {
        contentTypeBack: $contentTypeBack
        contentTypeFront: $contentTypeFront
        documentType: $documentType
        expirationDate: $expirationDate
      }
    ) {
      ...IdentityProofFields
    }
  }
`

export type UpdateIdentityProofVars = {
  id: string
}

export type UpdateIdentityProofData = {
  updateIdentityProof: string
}

export const UPDATE_IDENTITY_PROOF_MUTATION = gql`
  mutation UpdateIdentityProof(
    $id: ID!
  ) {
    updateIdentityProof(
      input: {
        id: $id
      }
    )
  }
`

export type IdentityProofView = {
  id: string
  contentType: PoiContentType
  documentType: PoiDocumentType
  expirationDate?: string
  storageUrl?: string
}

export const IDENTITY_PROOF_VIEW_FIELDS = gql`
  fragment IdentityProofViewFields on IdentityProofView {
    id
    contentType
    documentType
    expirationDate
    storageUrl
  }
`

export type IdentityProofsVars = Record<string, never>

export type IdentityProofsData = {
  identityProofs: IdentityProofView[]
}

export const IDENTITY_PROOFS_QUERY = gql`
  ${IDENTITY_PROOF_VIEW_FIELDS}
  query IdentityProofs {
    identityProofs {
      ...IdentityProofViewFields
    }
  }
`

export type RenewIdentityProofsVars = {
  contentTypeBack?: string
  contentTypeFront: string
  documentType: string
  expirationDate: string | null
}

export type RenewIdentityProofsData = {
  renewIdentityProofs: IdentityProof[]
}

export const RENEW_IDENTITY_PROOFS_MUTATION = gql`
  ${IDENTITY_PROOF_FIELDS}
  mutation RenewIdentityProofs(
    $contentTypeBack: String
    $contentTypeFront: String!
    $documentType: String!
    $expirationDate: ISO8601Date
  ) {
    renewIdentityProofs(
      input: {
        contentTypeBack: $contentTypeBack
        contentTypeFront: $contentTypeFront
        documentType: $documentType
        expirationDate: $expirationDate
      }
    ) {
      ...IdentityProofFields
    }
  }
`
