import { gql } from '@apollo/client'

export type QuoteSymbol = 'BTC' | 'UF' | 'USDT'

export type ConversionCurrency = 'USD' | 'CLP'

export type AccountStatus =
  'IDENTITY_PENDING_REVIEW'
  | 'IDENTITY_PENDING_CHANGES'
  | 'IDENTITY_REJECTED'
  | 'IDENTITY_VERIFIED'
  | 'IDENTITY_UNVERIFIED'
  | 'EMAIL_UNCONFIRMED'
  | 'UNKNOWN'

export type PendingKycRenewal = 'IDENTITY_PROOFS'

export const getRenewalData = (renewal: PendingKycRenewal) => {
  switch (renewal) {
  case 'IDENTITY_PROOFS': return {
    page: '/renewal/identity-proofs/',
    label: 'Necesitamos que actualices tus documentos de identidad',
  }
  }
}

export type AccountInformationVars = Record<string, never>

export type AccountInformationData = {
  accountInformation: {
    accountStatus: AccountStatus
    email: string
    isBusiness?: boolean
    nickName: string
    legalName?: string
    legalDocumentNumber?: string
    lastVerificationUpdate: string
    pendingKycRenewals: PendingKycRenewal[]
    quoteSymbol: QuoteSymbol
    conversionCurrency: ConversionCurrency
  }
}

export const ACCOUNT_INFORMATION_QUERY = gql`
  query AccountInformation {
    accountInformation {
      accountStatus
      email
      isBusiness
      nickName
      legalName
      legalDocumentNumber
      lastVerificationUpdate
      pendingKycRenewals
      quoteSymbol
      conversionCurrency
    }
  }
`
