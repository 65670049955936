import { gql } from '@apollo/client'

import { SPOUSE_IDENTITY_FIELDS } from './spouse_identities'

import type { SpouseIdentity, SpouseIdentityInput } from './spouse_identities'

export type CivilState = 'SINGLE' | 'DIVORCED' | 'CIVIL_PARTNER' | 'MARRIED' | 'WIDOWER'

export const CIVIL_STATE_LABELS: { [key in CivilState]: string } = {
  'SINGLE': 'Soltero(a)',
  'DIVORCED': 'Divorciado(a)',
  'CIVIL_PARTNER': 'Conviviente civil',
  'MARRIED': 'Casado(a)',
  'WIDOWER': 'Viudo(a)',
}

export const civilStateStr = (civilState?: CivilState) => (
  civilState ? CIVIL_STATE_LABELS[civilState] : 'Sin declarar'
)

export type PersonIdentity = {
  birthdate: string
  civilState?: CivilState
  familyName: string
  givenName: string
  id: string
  identificationNumber: string
  nationality: string
  isPoliticallyExposed: boolean
  spouseIdentity?: SpouseIdentity
}

export const PERSON_IDENTITY_FIELDS = gql`
  ${SPOUSE_IDENTITY_FIELDS}
  fragment PersonIdentityFields on PersonIdentity {
    birthdate
    civilState
    familyName
    givenName
    id
    identificationNumber
    nationality
    isPoliticallyExposed
    spouseIdentity {
      ...SpouseIdentityFields
    }
  }
`

export type PersonIdentityVars = Record<string, never>

export type PersonIdentityData = {
  personIdentity: PersonIdentity
}

export const PERSON_IDENTITY_QUERY = gql`
  ${PERSON_IDENTITY_FIELDS}
  query PersonIdentity {
    personIdentity {
      ...PersonIdentityFields
    }
  }
`

export type UpdatePersonIdentityVars = {
  birthdate: string
  civilState?: string
  familyName: string
  givenName: string
  identificationNumber: string
  nationality: string
  isPoliticallyExposed: boolean
  spouseIdentity?: SpouseIdentityInput
}

export type UpdatePersonIdentityData = {
  updatePersonIdentity: string
}

export const UPDATE_PERSON_IDENTITY_MUTATION = gql`
  mutation UpdatePersonIdentity(
    $birthdate: ISO8601Date!
    $civilState: String
    $familyName: String!
    $givenName: String!
    $identificationNumber: String!
    $nationality: String!
    $isPoliticallyExposed: Boolean!
    $spouseIdentity: SpouseIdentityInput
  ) {
    updatePersonIdentity(
      input: {
        birthdate: $birthdate
        civilState: $civilState
        familyName: $familyName
        givenName: $givenName
        identificationNumber: $identificationNumber
        nationality: $nationality
        isPoliticallyExposed: $isPoliticallyExposed
        spouseIdentity: $spouseIdentity
      }
    )
  }
`

export type UpdatePersonPersonIdentityVars = UpdatePersonIdentityVars & {
  primaryPhoneNumber: string
  secondaryPhoneNumber?: string
}

export type UpdatePersonPersonIdentityData = {
  updatePersonIdentity: string
  updatePhoneNumber: string
}

export const UPDATE_PERSON_PERSON_IDENTITY_MUTATION = gql`
  mutation UpdatePersonPersonIdentity(
    $birthdate: ISO8601Date!
    $civilState: String
    $familyName: String!
    $givenName: String!
    $identificationNumber: String!
    $nationality: String!
    $isPoliticallyExposed: Boolean!
    $spouseIdentity: SpouseIdentityInput
    $primaryPhoneNumber: String!
    $secondaryPhoneNumber: String
  ) {
    updatePersonIdentity(input: {
      birthdate: $birthdate
      civilState: $civilState
      familyName: $familyName
      givenName: $givenName
      identificationNumber: $identificationNumber
      nationality: $nationality
      isPoliticallyExposed: $isPoliticallyExposed
      spouseIdentity: $spouseIdentity
    })
    updatePhoneNumber(input: {
        primary: $primaryPhoneNumber
        secondary: $secondaryPhoneNumber
    })
  }
`
